<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="pathologyFields" />
    </CCardBody>
  </CCard>
</template>

<script>

import { createNewPathology } from '@/services/pathologies';
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
  name: "addPathology",
  components: { AbstractForm },
  data() {
    return {
      title: "Nueva patología",
      id: null,
      pathologyFields: {
        titlePathology: null,
        descriptionPathology: null
      },
      formContent: {
        buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Crear',
                    color: 'success',
                    event: 'add',
                    style: 'margin: 10px',
                    active: this.$store.state.isAdmin
                },
          ],
          rows: [
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'input',
                   value: '',
                   key: 'name',
                   label: 'Nombre de la patología',
                   maxLength: 30,
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'textArea',
                   value: '',
                   key: 'description',
                   label: 'Descripción',
                  maxLength: 1000,
                  },
                },
              ]
            },
          ]
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    onEmitMethods(event, dataPathology) {
        switch (event) {
            case 'add': this.addPathology(dataPathology); break;
            case 'back': this.back(); break;
            default: break;
        }
    },
    showAlertError(msg) {
       this.$store.state.errorAlert = { status: true, msg: msg.toString() }
    },
    /**
     * Método para validar los inputs del formulario
      */
    checkInputs(dataPathology) {
        const validationRules = [
            { field: 'name', message: 'Introduzca el nombre de la patología (máximo de 30 caracteres).', maxLength: 30 },
            { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },

        ];
        for (const rule of validationRules) {
            const value = dataPathology[rule.field];
            if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength)) {
                this.showAlertError(rule.message);
                return false;
            }
        }
            return true;
    },
    /**
     * Funcion encargada de crear la patología
     */
    addPathology(dataPathology) {
      if (this.checkInputs(dataPathology) == false) return
        
      createNewPathology(dataPathology)
          .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
          .then(() => {
            this.$router.push("/pathology")
      });
    },
     /**
     * Función para volver atrás
     */
    back() {
      this.$router.push(`/pathology`)
    },
  }
};
</script>
<style>
textarea.form-control{
  height: 200px;
}
</style>